import { useQuery, useMutation, useQueryClient, UseQueryOptions } from 'react-query';
import { COOKIES_CONFIG } from '@belong/common';
import { parseCookies } from 'nookies';
import { UserBasicInfoModel, UserModel } from 'src/api/models';
import {
  fetchUserClaimsService,
  fetchUserProfileService,
  fetchUserService,
  updateUserProfileService,
} from 'src/api/users';
import { getJwtToken } from 'src/utils/api-client';
import { object, Infer, optional, string } from 'superstruct';

const { JWT_TOKEN } = COOKIES_CONFIG;

export type ContactInfo = {
  email: string;
  phone: string;
};

export const UserProfile = object({
  homeTown: optional(string()),
  aboutMe: optional(string()),
  firstName: string(),
  lastName: string(),
  email: string(),
  phone: optional(string()),
  leadSource: optional(string()),
  leadMedium: optional(string()),
});

export type UserProfile = Infer<typeof UserProfile>;

export type UserClaimModel = {
  isHomeOwner: boolean;
  isResident: boolean;
};

export function useUser() {
  const cookies = parseCookies();
  const jwtToken = cookies[JWT_TOKEN.name] || getJwtToken();
  const { data, isLoading, refetch } = useQuery<UserModel>('user', () => fetchUserService(), {
    retry: 0,
    // should be refetched in the background every 24 hours
    staleTime: 1000 * 60 * 60 * 24,
    enabled: !!jwtToken,
  });

  return {
    user: data,
    isLoadingUser: isLoading,
    refetchUser: refetch,
  };
}

export function useMutateUser() {
  const queryClient = useQueryClient();
  return useMutation(() => fetchUserService(), {
    onSuccess: (data) => {
      queryClient.setQueryData('user', data);
    },
  });
}

export function useUserProfile(options?: UseQueryOptions<UserBasicInfoModel>) {
  return useQuery<UserBasicInfoModel>('users/profile', () => fetchUserProfileService(), options);
}

export function useUpdateUserProfile() {
  const queryClient = useQueryClient();

  return useMutation(updateUserProfileService, {
    onSuccess: () => {
      queryClient.invalidateQueries(['user', 'users/profile']);
    },
  });
}

export function useUserClaims(options?: UseQueryOptions<UserClaimModel>) {
  const { data, refetch } = useQuery<UserClaimModel>('users/claims', () => fetchUserClaimsService(), options);

  return {
    userClaims: data,
    refetchUserClaims: refetch,
  };
}
